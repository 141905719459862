export const gamesUrlList = [
  //0
  {name: 'lady-of-fortune', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=ladyoffortune&gameId=278&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country='},
  //1
  {name: 'jade-magician', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=jademagician&gameId=326&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime='},
  //2
  {name: 'aztec', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=aztecwarriorprincess&gameId=332&lang=en&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country='},
  //3
  {name: 'the-magic-cauldron', link: 'https://demogamesfree.pragmaticplay.net/gs2c/openGame.do?lang=en&cur=USD&gameSymbol=vs20magicpot&websiteUrl=https%3A%2F%2Fdemogamesfree.pragmaticplay.net&jurisdiction=99&lobbyURL=https%3A%2F%2Fwww.pragmaticplay.com'},
  //4
  {name: 'super-flip', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=superflip&gameId=295&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country='},
  //5
  {name: 'dragon-maiden', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=dragonmaiden&gameId=355&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime='},
  //6
  {name: 'coils-of-cash', link: 'https://asccw.playngonetwork.com/Casino/IframedView?pid=2&gid=coilsofcash&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%25&height=100%25&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country=&pauseplay=&playlimit=&selftest=&sessiontime=&coreweburl=https://asccw.playngonetwork.com/&showpoweredby=True'},
  //7
  {name: '5x-magic', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=5xmagic&gameId=199&lang=en_GB&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country='},
  //8
  {name: 'street-magic', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=streetmagic&gameId=351&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country='},
  //9
  {name: 'big-win-cat', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=bigwincat&gameId=342&lang=en_US&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country='},
  //10
  {name: 'fire-joker', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&gid=firejoker&gameId=307&lang=en&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&user=&password=&ctx=&demo=2&brand=&lobby=&rccurrentsessiontime=0&rcintervaltime=0&rcaccounthistoryurl=&rccontinueurl=&rcexiturl=&rchistoryurlmode=&autoplaylimits=0&autoplayreset=0&callback=flashCallback&rcmga=&resourcelevel=0&hasjackpots=False&country='},
  //11
  {name: 'wild-falls', link: 'https://asccw.playngonetwork.com/casino/IframedView?pid=2&lang=en_GB&practice=1&channel=desktop&div=flashobject&width=100%&height=100%&demo=2&rccurrentsessiontime=0&rcintervaltime=0&autoplaylimits=0&autoplayreset=0&callback=flashCallback&resourcelevel=0&gid=wildfalls&gameId=350'}
];