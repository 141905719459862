import {useEffect} from 'react';
import Footer from '../components/Footer';
import './PageNotFound.css';

function PageNotFound() {

  useEffect(() => {
    document.title = 'Joejackpots - 404';
  }, []);

  return (
    <div className="page-not-found-container">
      <div className="page-not-found-block">
        <div className='page-not-found-main-img'>
          <img width='893' height='600' src="/assets/images/404-main.webp" alt="" />
        </div>
      </div>
      <Footer className={'page-not-found-footer'} />
    </div>
  );
}

export default PageNotFound;
