import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {useCookies} from 'react-cookie';
import {gamesUrlList} from "../data/gamesUrlList";
import Play from '../pages/Play';
import PageNotFound from '../pages/PageNotFound';

const PlayRouteHandler = () => {
    const { gameUrl } = useParams();
    const [cookies] = useCookies(['is18']);
    const [cookiePopup] = useCookies(['isPopup']);
    const [url, setUrl] = useState(null);

    useEffect(() => {
        const filteredGame = gamesUrlList.find(game => game.name === gameUrl);

        if (filteredGame) {
            setUrl(filteredGame.link);
        }
    }, [gameUrl]);

    if (url) {
        return <Play is18={cookies.is18} isPopup={cookiePopup.isPopup} url={url} />;
    } else {
        return <PageNotFound />;
    }
};

export default PlayRouteHandler;
