import React, {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {UserContext} from '../../context/UserContext';
import {useUser} from '../../customHooks/userHooks';
import {gamesUrlList} from '../../data/gamesUrlList';


import './TopGames.css';

function TopGames() {
  let navigate = useNavigate();
  const { openRegisterPopup, setCurrentGame, getRandomGame, isOneGame, isLoading } = useContext(UserContext);
    const { showRegisterBtn } = useUser();

    const topGamesArr = ['big-win-cat', 'fire-joker', 'dragon-maiden', '5x-magic', 'wild-falls', 'coils-of-cash'];

    if (isLoading) {
        return <p>Loading...</p>;
    }

  return (
    <>
      <div className="top-games-block">
          <div className="main-container">
              {isOneGame === 'true' ?
                  <div className="top-games-wrap isOneGame">
                      <h2 className="title">Our Top Game</h2>
                      <p className='banner-text'>Here is Joejackpots Casino's pick of the week. This selection is the result of the choice of our visitors. Check it out!
                      </p>
                      <div className="top-games">
                          {gamesUrlList.slice(0, 1).map(({name}, i) =>
                              <div className="game-block" key={i}>
                                  <div className='hover-img'>
                                      <button onClick={() => {
                                          if(showRegisterBtn){
                                              openRegisterPopup(false);
                                              setCurrentGame(`${name}`);
                                          }else{
                                              navigate(`/play/lady-of-fortune/`);
                                          }
                                      }}>Play now</button>
                                  </div>
                                  <img width='182' height='182' src={`/assets/images/home_games/${name}.webp`} className="first-screen-game" alt="banner" />
                              </div>
                          )}
                      </div>
                      <button className='banner-btn main-btn' onClick={() => {
                          if(showRegisterBtn){
                              openRegisterPopup(false);
                              setCurrentGame('');
                          }else{
                              navigate(`/play/lady-of-fortune/`);
                          }
                      }}>Start Now</button>
                  </div>
                    :
                  <div className="top-games-wrap">
                      <h2 className="title">Our Top Games and Slots</h2>
                      <p className='banner-text'>Here are the best games of Joejackpots Casino. This list is the result of the choice of our visitors. Check it out and choose a game to play!
                      </p>
                      <div className="top-games">
                          {topGamesArr.map((gameName, i) =>
                              <div className="game-block" key={i}>
                                  <div className='hover-img'>
                                      <button onClick={() => {
                                          if(showRegisterBtn){
                                              openRegisterPopup(false);
                                              setCurrentGame(`${gameName}`);
                                          }else{
                                              navigate(`/play/${gameName}/`);
                                          }
                                      }}>Play now</button>
                                  </div>
                                  <img width='182' height='182' src={`/assets/images/home_games/${gameName}.webp`} className="first-screen-game" alt="banner" />
                              </div>
                          )}
                      </div>
                      <button className='banner-btn main-btn' onClick={() => {
                          if(showRegisterBtn){
                              openRegisterPopup(false);
                              setCurrentGame('');
                          }else{
                              navigate(`/play/${getRandomGame(gamesUrlList)}/`);
                          }
                      }}>Start Now</button>
                  </div>

              }
          </div>
      </div>
    </>
  );
}

export default TopGames;
