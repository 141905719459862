import {useContext, useEffect, useState} from 'react';
import checkUserAuth from '../data/userData';
import {useLocation} from "react-router-dom";
import {UserContext} from '../context/UserContext';


export const useUser = () => {
  const { pathname } = useLocation();
  const { cookies, showRegisterPopup, setShowRegisterPopup } = useContext(UserContext);

  const [isDataLoading, setDataLoading] = useState(false);
  const [showRegisterBtn, setShowRegisterBtn] = useState(true);

  useEffect(() => {
    // console.log(pathname.split('/').includes('play'));

    async function main() {
      try {
        const result = await checkUserAuth();
        if (result.is_auth === true) {
          setShowRegisterBtn(false);
          setShowRegisterPopup(false);
        } else if(result.is_auth === false){
          if(pathname.split('/')[1] === "play"){
            setTimeout(() => {
              if(cookies.is18 === 'false') {
                setShowRegisterPopup(false);
              } else if(cookies.is18 === 'true') {
                  setShowRegisterPopup(true);
              }
            }, 30000)
          }else if(pathname.split('/')[1] !== "play"){
              setShowRegisterPopup(false);
          }
        }
      } catch (error) {
        console.error(error);
      }
    }
    main();
  }, [cookies.is18, pathname]);

  return {
    isDataLoading,
    setDataLoading,
    showRegisterBtn,
    setShowRegisterBtn,
    showRegisterPopup,
    setShowRegisterPopup,
    cookies
  };
};
