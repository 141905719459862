import React, {useContext} from 'react';
import {useNavigate} from 'react-router-dom';
import {UserContext} from '../../context/UserContext';
import {useUser} from '../../customHooks/userHooks';

import './TopGames.css';

function TopGames() {
  let navigate = useNavigate();
    const { openRegisterPopup, setCurrentGame } = useContext(UserContext);
    const { showRegisterBtn } = useUser();
    const lpTopGamesArr = ['lady-of-fortune', 'jade-magician', 'aztec', 'the-magic-cauldron', 'super-flip', 'dragon-maiden', 'coils-of-cash', '5x-magic', 'street-magic', 'big-win-cat', 'fire-joker', 'wild-falls'];

  return (
    <>
      <div className="lp-top-games-block">
          <div className="lp-top-games-container main-container">
              <div className="lp-top-games-text-block">
                  <h1 className="lp-top-games-title title">Top game selection</h1>
              </div>
              <div className="lp-top-games__games-wrap">
                  <div className="lp-top-games__games">
                      {window.innerWidth <= 1100 ?
                          lpTopGamesArr.map((gameName, i) =>
                                  <div className="game-block" key={i}>
                                      <div className='hover-img'>
                                          <button onClick={() => {
                                                if(showRegisterBtn){
                                                    openRegisterPopup(false);
                                                    setCurrentGame(`${gameName}`);
                                                }else{
                                                    navigate(`/play/${gameName}/`);
                                                }
                                            }}>Play now</button>
                                      </div>
                                      <img width='180' height='180' src={`/assets/images/lp/${gameName}.webp`} className="first-screen-game" alt="banner" />
                                  </div>
                              ) :

                          <>
                                <div className="lp-top-games__games-firstline">
                                    {lpTopGamesArr.slice(0, 5).map((gameName, i) =>
                                        <div className="game-block" key={i}>
                                            <div className='hover-img'>
                                                <button onClick={() => {
                                                    if(showRegisterBtn){
                                                        openRegisterPopup(false);
                                                        setCurrentGame(`${gameName}`);
                                                    }else{
                                                        navigate(`/play/${gameName}/`);
                                                    }
                                                }}>Play now</button>
                                            </div>
                                            <img width='180' height='180' src={`/assets/images/lp/${gameName}.webp`} className="first-screen-game" alt="banner" />
                                        </div>)
                                    }
                                </div>
                                <div className="lp-top-games__games-secondline">
                                      {lpTopGamesArr.slice(5, 9).map((gameName, i) =>
                                          <div className="game-block" key={i}>
                                              <div className='hover-img'>
                                                  <button onClick={() => {
                                                        if(showRegisterBtn){
                                                            openRegisterPopup(false);
                                                            setCurrentGame(`${gameName}`);
                                                        }else{
                                                            navigate(`/play/${gameName}/`);
                                                        }
                                                    }}>Play now</button>
                                              </div>
                                              <img width='180' height='180' src={`/assets/images/lp/${gameName}.webp`} className="first-screen-game" alt="banner" />
                                          </div>)
                                      }
                                </div>
                                <div className="lp-top-games__games-thirdline">
                                      {lpTopGamesArr.slice(9).map((gameName, i) =>
                                          <div className="game-block" key={i}>
                                              <div className='hover-img'>
                                                  <button onClick={() => {
                                                        if(showRegisterBtn){
                                                            openRegisterPopup(false);
                                                            setCurrentGame(`${gameName}`);
                                                        }else{
                                                            navigate(`/play/${gameName}/`);
                                                        }
                                                    }}>Play now</button>
                                              </div>
                                              <img width='180' height='180' src={`/assets/images/lp/${gameName}.webp`} className="first-screen-game" alt="banner" />
                                          </div>)
                                      }
                                  </div>
                          </>
                      }
                  </div>
              </div>
              <img width="644" height="387" className="lp-top-games-image" src="/assets/images/lp/lp-book.webp" alt="background" />
          </div>
      </div>
    </>
  );
}

export default TopGames;
