import React, {useContext, useEffect} from 'react';
import Footer from '../components/Footer';
import './About.css';
import {UserContext} from "../context/UserContext";

function About() {
    const { isOneGame } = useContext(UserContext);

  useEffect(() => {
    document.title = 'Joejackpots - About';
  }, []);

  return (
    <>
        <div className="about-body">
            {window.innerWidth <= 864 ? null : <img width='1728' height='1024' className="about-page-image" src="/assets/images/about-bg.webp" alt="" />}
            <div className="about-container main-container">
                <div className="about-text-container">
                    <h2 className='about-title title'>The free game planet welcomes you!</h2>
                    {isOneGame === 'true' ?
                        <p>Hey! Let’s meet our planet more closely. We are a magic place in a magic universe where we believe that every guest deserves a good entertainment. That’s why we created here a special social casino for everyone, who wants to play and doesn’t want to lose the money. Playing our carefully selected game is just the same as in any conventional casino. With a slight difference: our game is absolutely free of charge. You can play it anytime, everywhere. Use your computer or a mobile device, register and start playing!</p>
                            :
                        <p>Hey! Let’s meet our planet more closely. We are a magic place in a magic universe where we believe that every guest deserves a good entertainment. That’s why we created here a special social casino for everyone, who wants to play and doesn’t want to lose the money. In our game collection, you’ll find many, many games and slots, just the same as in any conventional casino. With a slight difference: our games are absolutely free of charge. You can play them anytime, everywhere. Use your computer or a mobile device, register and start playing!
                        </p>
                    }
                </div>
                {/*<div className="about-img-container">*/}
                {/*  <img src="/assets/images/about-main.webp" alt="about" className="about-image" />*/}
                {/*</div>*/}
            </div>
        </div>
        <Footer />
    </>);
}

export default About;
