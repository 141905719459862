import React, {useContext} from 'react';
import {Link, useLocation} from 'react-router-dom';
import './CookiePopup.css';
import {UserContext} from "../context/UserContext";

function CookiePopup() {
    let { pathname } = useLocation();
    const { setCookiePopup, setShowCookiePopup } = useContext(UserContext);
    const hideCookiePopup = () => {
        setCookiePopup('isPopup', true, { path: '/' });
        setShowCookiePopup(false);
    }

    return (
        <div id="cookie-block-cover" className="cookie-block-wrap" role="alert">
            <div className="cookie-block">
                <div className="cookie-block-inner">
                    <div className="cookie-block__text">
                        <h4>WE USE COOKIES</h4>
                        <p>We use our own and third-party cookies to personalize content and to analyze web traffic.
                        </p>
                        <Link target="_blank" to="/cookies/">Read more about cookies</Link>
                    </div>
                    <div className="cookie-block__buttons">
                        <button type="button" className="btn-yes-cookies" onClick={hideCookiePopup} id="btn-yes-cookies">
                            Yes
                        </button>
                        <button type="button" className="btn-no-cookies" onClick={hideCookiePopup} id="btn-no-cookies">
                            No
                        </button>
                    </div>
                </div>
                <div className="cookie-block-image">
                    <img width='313' height='346' src="/assets/images/cookies.webp" className="cookies-image" alt="banner" />
                </div>
            </div>
        </div>
    )
}
export default CookiePopup;