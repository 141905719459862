import React, {createContext, useEffect, useState} from "react";
import {useLocation, useNavigate} from 'react-router-dom';
import {useCookies} from 'react-cookie';
import {checkAuth, logout} from '../data/dataApi';
import {gamesUrlList} from "../data/gamesUrlList";

export const UserContext = createContext();

export const UserProvider = ({ children }) => {
    const { pathname } = useLocation();
  const navigate = useNavigate();
  const [showRegisterPopup, setShowRegisterPopup] = useState(false);
  const [showThanksPopup, setShowThanksPopup] = useState(false);
  const [loginPopupStatus, setLoginPopupStatus] = useState(false);
  const [mobMenu, setMobMenu] = useState(false);
  const [currentGame, setCurrentGame] = useState('');
  const [cookies, setCookie] = useCookies(['is18']);
  const [cookiePopup, setCookiePopup] = useCookies(['isPopup']);
  const [showCookiePopup, setShowCookiePopup] = useState(true);
  const [showAgePopup, setShowAgepopup] = useState(false);
  const [showRegisterBtn, setShowRegisterBtn] = useState(true);
  const [isOneGame, setIsOneGame] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

    const toggleMobMenu = () => {
    setMobMenu(!mobMenu);
    console.clear();
  };

    useEffect(() => {
        const checkIsOneGame = async () => {
            setIsLoading(true);
            try {
                const response = await fetch('/api/site/params.php');
                const data = await response.json();
                if (data.one_game === 'true' || data.one_game === true) {
                    setIsOneGame('true');
                } else {
                    setIsOneGame('false');
                    throw new Error('ONE_GAME variable not found');
                }
            } catch (error) {
                console.error('Error fetching config:', error);
                setIsOneGame('false');
            } finally {
                setIsLoading(false);
            }
        };

        checkIsOneGame();
    }, []);


  const openRegisterPopup = (login = false) => {
    if (login) {
      setShowRegisterPopup(true);
      setLoginPopupStatus(true);
      setMobMenu(false);
      setCurrentGame('');
    } else {
      setShowRegisterPopup(true);
      setLoginPopupStatus(false);
      setMobMenu(false);
      setCurrentGame('');
    }
  };

  const closeRegisterPopup = () => {
    setShowRegisterPopup(false);
    setLoginPopupStatus(false);
  };

  const skipAndPlay = () =>{
      setShowRegisterPopup(false);
      setLoginPopupStatus(false);
      if (currentGame !== '') {
          navigate(`/play/${currentGame}/`);
      }else{
          if (pathname === '/lp/' || pathname === '/lp') {
              navigate(`/lp/`);
          }else {
              navigate(isOneGame !== 'true' ? '/all-games/' : `/play/${gamesUrlList[0]?.name}/`);
          }
      }
  }

  const userRegistered = () => {
    closeRegisterPopup();
    setShowThanksPopup(true);
  };

  const userLogged = () => {
    closeRegisterPopup();
    window.location.reload();
  };

  const closeThanksPopup = () => {
    setShowThanksPopup(false);
    if (pathname === '/lp/' || pathname === '/lp') {
      window.location.href = "/lp/";
    } else if (pathname === '/top5/' || pathname === '/top5') {
      window.location.href = "/top5/";
    } else {
      navigate(0);
    }
  };

  const userLogout = async () => {
    const url = logout();
    await fetch(url)
      .then((response) => response.json())
      .then((result) => {
        navigate(0);
      })
      .catch((error) => {
        console.error('Error:', error);
      });
  };
    const getRandomGame = (arr) =>{
        function randomRange(min, max) {
            return (Math.random() * (max - min + 1)) + min
        }
        const game = parseInt(randomRange(0, arr.length - 1));
        const gameName = arr[game].name
        return gameName;
    }

  return (
    <UserContext.Provider
      value={{
        mobMenu,
        showRegisterPopup,
        setShowRegisterPopup,
        showThanksPopup,
        setShowThanksPopup,
        cookies,
        setCookie,
        showRegisterBtn,
        setShowRegisterBtn,
        openRegisterPopup,
        closeRegisterPopup,
        skipAndPlay,
        userRegistered,
        userLogged,
        closeThanksPopup,
        loginPopupStatus,
        userLogout,
        toggleMobMenu,
        showAgePopup,
        setShowAgepopup,
        cookiePopup,
        setCookiePopup,
        showCookiePopup,
        setShowCookiePopup,
        currentGame,
        setCurrentGame,
        getRandomGame,
          isOneGame,
          isLoading
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
