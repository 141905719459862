import React, {useEffect, useState} from 'react';
import {BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import {useCookies} from 'react-cookie';
import {UserProvider} from '../context/UserContext';

import OpenPageOnTop from './OpenPageOnTop';
import Header from './Header';
import Home from '../pages/Home';
// import Play from '../pages/Play';
import PlayRouteHandler from './PlayRouteHandler';
import About from '../pages/About';
import Contact from '../pages/Contact';
import Legal from "../pages/Legal";
import Lp from '../pages/Lp';
import Top5 from '../pages/Top5';
import AllGames from '../pages/AllGames';
import PageNotFound from '../pages/PageNotFound';

import './App.css';

function App() {
  const [cookies, setCookie] = useCookies(['is18']);
  const [cookiePopup, setCookiePopup] = useCookies(['isPopup']);
  const [countryData, setCountryData] = useState('UA');

  useEffect(() => {
    fetch(`https://ipapi.co/json/?key=1W62DydpgUZwtxR5l6BW8UPckBu2NLTTJLzOiuqtkwHgz4tNXz`)
      .then((response) => response.json())
      .then((response) => {

        let data = {
          code: response.country_code,
          country: response.country_name,
        };
        setCountryData(data);
      })
      .catch((error) => {
        console.error('Error:', error);
      });

  }, []);

  if (cookies.is18 === undefined) {
    setCookie('is18', 'false', { path: '/' });
  }
  if(cookiePopup.isPopup === undefined) {
    setCookiePopup('isPopup', 'false', { path: '/' });
  }
  const pages = [
    { path: '/', element: <Home is18={cookies.is18} isPopup={cookiePopup.isPopup} /> },
    { path: '/home/', element: <Home is18={cookies.is18} isPopup={cookiePopup.isPopup} /> },
    { path: '/top5/', element: <Top5 is18={cookies.is18} isPopup={cookiePopup.isPopup} /> },
    { path: '/all-games/', element: <AllGames is18={cookies.is18} isPopup={cookiePopup.isPopup} /> },
    { path: '/lp/', element: <Lp is18={cookies.is18} isPopup={cookiePopup.isPopup} /> },
    { path: '/play/:gameUrl', element: <PlayRouteHandler /> },
    { path: '/about/', element: <About /> },
    { path: '/contact/', element: <Contact /> },
    { path: '/privacy/', element: <Legal /> },
    { path: '/terms/', element: <Legal /> },
    { path: '/cookies/', element: <Legal /> },
    { path: '/disclaimer/', element: <Legal /> },
    // { path: '/play/*', element: <PageNotFound /> },
    { path: '*', element: <PageNotFound /> },
  ];

  return (
    <Router>
      <UserProvider>
        <div className="App">
          <OpenPageOnTop />
          <Header countryData={countryData} />
          <Routes>
            {pages.map((route) => (
              <Route
                key={route.path}
                path={route.path}
                element={route.element}
              />
            ))}
          </Routes>
        </div>
      </UserProvider>
    </Router >
  );
}

export default App;
