import React, {useEffect} from 'react';
import Footer from '../../components/Footer';


function Disclaimer() {

    useEffect(() => {
        document.title = `Joejackpots - Disclaimer`;
    }, []);

    return (
        <>
            <div className="main-container">
                <div className="content-cover">
                    <h1 className='legal-title'>Disclaimer</h1>
                    <p>Please take a moment to read this important information regarding our game and slot content.</p>
                    <p>Our casino represents a social casino that is aimed at free entertainment only. While we strive to offer an enjoyable gaming experience to our players, it's important to note that we do not offer any real money prizes. Therefore, our platform's game and slot content should not be used as a source of income or a way to gamble for real money.</p>
                    <p>Due to the nature of our activity, we can only allow access to players above 18 years old. We take our responsibility to prevent underage gambling seriously and implement age verification measures to ensure that all players meet the required age criteria.</p>
                    <p>At Joejackpots, we support responsible play and urge our visitors to pay attention to how they play. Winning in a social casino does not guarantee future success in a real money game. Therefore, we recommend that players gamble within their means and always play responsibly.</p>
                    <p>By accessing and using our platform, you agree to comply with our terms and conditions and acknowledge that the game and slot content on our platform is for entertainment purposes only. If you have any questions or concerns, please do not hesitate to contact us.</p>
                    <p>Thank you for choosing Joejackpots as your entertainment destination!</p>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Disclaimer;