import React, {useEffect} from 'react';
import Footer from '../../components/Footer';

function Cookies() {

    useEffect(() => {
        document.title = `Joejackpots - Cookie Policy`;
    }, []);

    return (
        <>
            <div className="main-container">
                <div className="content-cover">
                    <h1 className='legal-title'>Cookie Policy</h1>
                    <p>Joejackpots Casino may use "cookies" on its website www.joejackpots.com to facilitate navigation through its web pages and to obtain statistical data.</p>
                    <p>A "cookie" is a tiny piece of data file stored on the user's device and can automatically send information to our server, without being able to read the data on the computer's hard drive, or other cookies created by other service providers. The "cookies" allow the services and contents to be customized for each user, as well as to measure the audience of the pages of www.joejackpots.com. The information obtained through these "cookies" is anonymous, so it cannot be associated with any user, nor will it allow the identification of their first and last names.</p>
                    <p>You may receive two types of cookies when visiting our website:</p>
                    <p>Third-party cookies , installed on your computer by the Google Analytics service. They are statistical cookies that allow us to know useful data to improve our page, such as the number of visits we have received or the hours of the greatest traffic.</p>

                    <p>If you do not wish to receive these cookies, you can set your browser to reject them. Otherwise, we understand that we have your consent for its installation.</p>
                    <p>Own authentication cookies are used to recognize registered visitors who have "logged in" to the web page. They are the cookies that allow the user to access the restricted access sections of the web in case they have been granted access codes for it.</p>
                    <p>If you reject their installation or delete them, your access keys will not work correctly.</p>
                    <p>Cookies are not computer viruses. They do not harm your computer or slow it down. The user has the possibility of configuring the browser of his computer according to his personal needs so that he can warn of the accept of "cookies" and prevent their installation on the hard drive.</p>

                <p>A  cookie is a harmless text file kept in your browser when visiting practically any web page. The cookie's purpose is that the website can remember your visit when you return to that page. Although many users do not know it, cookies have been used for 20 years since the first browsers for the World Wide Web came to this world.</p>
                <p>What IS NOT a cookie?</p>
                <p>The cookie is not a virus or a trojan. It is not a worm, spam, spyware, or anything else, nor does it open pop-up windows.</p>
                <p>What data does a cookie store?</p>
                <p>Cookies do not store sensitive or private information about you. The data they save is of a technical character, personal choices, content customization, etc.</p>
                <p>The web server does not associate you as an individual but with your web browser. Suppose you regularly browse with Internet Explorer and try to browse the same website with Firefox or Chrome. In that case, you will see that the website does not admit that you are the same person because you are associating the browser, not the person.</p>
                <p>What kind of cookies are there?</p>
                <p>Technical cookies: They are the most fundamental and allow, among other things, to know when a human or a mechanical application is browsing, when an unidentified user and a registered user are browsing, and essential tasks for the operation of any dynamic website.</p>
                <p>Analysis cookies: They collect information about the type of browser you are doing, the sections you use the most, products consulted, time slot of use, language, etc.</p>
                <p>Advertising cookies: They show advertising based on your browsing, your origin country, language, etc.</p>
                <p>What are own and third-party cookies?</p>
                <p>Own cookies are those generated by the page you are visiting and third-party cookies are those generated by external services or providers such as Facebook, Twitter, Google, etc.</p>
                <p>What happens if I disable cookies?</p>
                <p>To understand the scope that deactivating  cookies can have, we show you some examples:</p>
                <p>You cannot share content from this website on Facebook, Twitter or any other social network.</p>
                <p> The website will not be able to adapt the contents to your personal preferences, as is often the case in online stores.</p>
                <p>Customizing your geographic preferences, such as time zone, currency or language, will not be possible.
                        The website will not be able to perform web analytics on visitors and web traffic, making it difficult to compete.</p>
                <p>It will not be possible to show sectorized advertising, which will reduce the web advertising revenue.</p>

                <p>Can cookies be deleted?</p>
                <p>Yes. Not only delete, but also block, in a general or particular way for a specific domain.</p>
                    <p>To delete cookies from a website, you must go to your browser settings and there you can search for those associated with the domain in question and proceed to delete them.
                    </p>
                </div>
            </div>
            <Footer />
        </>
    );
}

export default Cookies;