import {useEffect} from 'react';
import {useNavigate, useParams} from 'react-router-dom';
import Footer from '../components/Footer';

import './Play.css';

function Play({ is18, url }) {
  let navigate = useNavigate();

  let { gameUrl } = useParams();
  // const [url, setUrl] = useState();

  function titleCase(str) {
    const splitStr = str.toLowerCase().split('-');
    for (let i = 0; i < splitStr.length; i++) {
      splitStr[i] = splitStr[i].charAt(0).toUpperCase() + splitStr[i].substring(1);
    }
    return splitStr.join(' ');
  }

  useEffect(() => {
    document.title = `Joejackpots - ${titleCase(gameUrl)}`;
  }, [ is18, navigate]);

  const clearConsole = () => {
    console.clear();
  };

  const goBack = () => {
    navigate(-1);
  };

  return (
    <div className="text-page-body" >
      <div className="main-container">
        <div className="play-game-cover">
          <div className="game-back-link" onClick={() => { clearConsole(); goBack(); }}><i className="fas fa-arrow-left"></i>Back</div>
          <div className="game-cover">
            <iframe title="game" src={url} frameBorder="0"></iframe>
          </div>
        </div>
      </div>
      <Footer />
    </div >
  );
}

export default Play;
